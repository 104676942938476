//jsxhook

import {
  rawColorValues,
  xmlToJson,
  MapMarker
} from "@sixty-six-north/ui-system"
import { useConfig } from "Config"
import { usePreferredLanguage, useStoreContext } from "i18n/StoreHooks"
import fetch from "isomorphic-unfetch"
import dynamic from "next/dynamic"
import React, { useEffect, useState } from "react"
import { Box } from "theme-ui"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"

const GoogleMap = dynamic(() =>
  import("@sixty-six-north/ui-system/dist/cjs/components/Maps/Maps").then(
    module => module.GoogleMap
  )
)

export const GoogleMapSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const language = usePreferredLanguage()
  const { country } = useStoreContext()
  const config = useConfig()

  // Load in the gpx file and check the google map is loaded
  const [mapData, setMapState] = useState({
    maps: {},
    map: {},
    mapsLoaded: false,
    dataLoaded: false,
    coordData: []
  })

  const gpxFileExists = adapter.gpxFile("").length > 0
  // only load this in once
  useEffect(() => {
    if (gpxFileExists && mapData.mapsLoaded) {
      fetch(adapter.gpxFile(""))
        .then(response => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(gpxData => {
          const json = xmlToJson(gpxData)
          return json.gpx.trk.trkseg.trkpt.map(point => {
            return {
              lat: Number(point["@attributes"].lat),
              lng: Number(point["@attributes"].lon)
            }
          })
        })
        .then(response =>
          setMapState({
            coordData: response,
            maps: mapData.maps,
            map: mapData.map,
            mapsLoaded: mapData.mapsLoaded,
            dataLoaded: true
          })
        )
    }
  }, [mapData.mapsLoaded])

  // data loaded
  useEffect(() => {
    const execute = async () => {
      if (gpxFileExists && mapData.dataLoaded && mapData.mapsLoaded) {
        //@ts-expect-error Polyl
        const Polyline = mapData.maps.Polyline

        const paths = { path: mapData.coordData }
        const line = new Polyline(
          Object.assign(
            {},
            {
              geodesic: true,
              strokeColor: rawColorValues["white"][0],
              strokeOpacity: 0.7,
              strokeWeight: 2
            },
            paths
          )
        )

        line.setMap(mapData.map)

        // Get bounds by our places
        const bounds = (
          await import(
            "@sixty-six-north/ui-system/dist/cjs/components/Maps/Maps"
          )
        ).getMapBounds(mapData.maps, mapData.coordData)
        // Fit map to bounds
        //@ts-expect-error fitBounds not defined on type
        mapData.map.fitBounds(bounds)
      }
    }
    execute()
  }, [mapData.dataLoaded])

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          pb: "56%",
          height: 0
        }}
      >
        <GoogleMap
          apiKey={config.google.mapKey}
          yesIWantToUseGoogleMapApiInternals={true}
          language={language}
          region={country}
          onGoogleApiLoaded={ob => {
            setMapState({
              map: ob.map,
              maps: ob.maps,
              mapsLoaded: true,
              dataLoaded: mapData.dataLoaded,
              coordData: mapData.coordData
            })
          }}
        >
          {data.items &&
            data.items.map((marker, idx) => {
              const itemAdaptor = new PrismicItemAdapter(marker)

              const markerCoords = {
                lat: marker.coordinates?.latitude,
                lng: marker.coordinates?.longitude
              }
              return (
                <MapMarker
                  key={`gmap-marker-${idx})`}
                  label={itemAdaptor.label("")}
                  {...markerCoords}
                ></MapMarker>
              )
            })}
        </GoogleMap>
      </Box>
    </PrismicElementWrapper>
  )
}
